import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://pypi.org/project/websockets-router/"
      }}>{`websockets-router`}</a></h1>
    <p>{`This is one of the hardest libraries that I have built, I had a problem where I needed to create some real-time solution for a IoT device,
at that time I was handling a lot of pressure because I was the main backend developer/Project owner and I didn't find a good WebSocket
framework for python.`}</p>
    <p>{`So I took the `}<a parentName="p" {...{
        "href": "https://websockets.readthedocs.io/en/stable/"
      }}>{`websockets library`}</a>{` and by reading some of the code in the
`}<a parentName="p" {...{
        "href": "https://bottlepy.org/docs/dev/"
      }}>{`bottlepy Micro framework`}</a>{`.`}</p>
    <p>{`The job was done, and I could have multiple endpoints on one WS server, with this I create 2 endpoints one for the IoT device to send
and update request, and another one for the clients, it was not real-time because the IoT devices send the data in intervals but if we at
the moment the server received the data the client-side received an update.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      